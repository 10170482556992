// import { aklivityContent } from './Aklivity';
import { bemarkeContent } from './Bemarke';
import { eatFreshContent } from './EatFresh';
import { favroContent } from './Favro';
import { lempireContent } from './Lempire';
import { meteorContent } from './Meteor';
import { montiApmContent } from './MontiAPM';
import { pathableContent } from './Pathable';
import { sekrContent } from './Sekr';
import { petParkerContent } from './PetParker';
import { poliDigitalContent } from './PoliDigital';
import { sneakpeekContent } from './Sneakpeek';
import { sostenaContent } from './Sostena';
import { unlocContent } from './Unloc';
import { zcloudContent } from './zCloud';

export const nextCsList = [
  // Featured -----------------------------------
  {
    ...petParkerContent,
  },
  {
    ...montiApmContent,
  },
  // Regular ------------------------------------
  // {
  //   ...aklivityContent,
  // },
  {
    ...bemarkeContent,
  },
  {
    ...eatFreshContent,
  },
  {
    ...favroContent,
  },
  {
    ...lempireContent,
  },
  {
    ...sneakpeekContent,
  },
  {
    ...poliDigitalContent,
  },
  {
    ...sostenaContent,
  },
  {
    ...unlocContent,
  },
  {
    ...zcloudContent,
  },
  {
    ...meteorContent,
  },
  {
    ...pathableContent,
  },
  {
    ...sekrContent,
  },
];
