import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDiagramProject,
  faGaugeCircleBolt,
  faServer,
  faUsersRectangle,
} from '@fortawesome/pro-light-svg-icons';
import { ExpertList } from './Sections/OurExperts';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';

const aiServices = [
  {
    icon: faDiagramProject,
    title: 'Quave AI Framework',
    text: 'We are not talking about code. Our AI framework is a structured approach to develop and implement AI solutions. It outlines key components, processes, and best practices that provide an organized way to tackle complex projects efficiently.',
  },
  {
    icon: faUsersRectangle,
    title: 'AI Expertise',
    text: `We're experienced in AI, with a proven track record. Our team has worked on diverse projects, from LLM trip planners to machine learning models. We're ready for your project. In AI, ideas and problem-solving approaches are crucial, not just cheap talk.`,
  },
  {
    icon: faGaugeCircleBolt,
    title: 'Integrations, Data and APIs',
    text: 'We combine our AI expertise with API development skills to create custom solutions for your business. These products can integrate AI into your existing systems or power new AI-driven applications. We can speed up your projects by leveraging our existing APIs and data sources.',
  },
  {
    icon: faServer,
    title: 'Infrastructure',
    text: 'How common is to find people who understand AI and infrastructure? We are experts in both. We can help you build custom infrastructure to support your projects. We can help you build scalable, secure, and reliable infrastructure that meets your AI needs.',
  },
];

const ConsultingService = ({ toggleContactUs }) => (
  <>
    <section data-id="hero" className="sm:px-4">
      <div className="mx-auto max-w-uwide flex flex-center flex-col bg-moonstone-300 bg-service-consulting sm:rounded-8 lg:h-[500px] xl:h-[620px]">
        <div className="container 2xl:max-w-default text-white text-center py-16 px-4">
          <h1 className="text-h3 md:text-h2 lg:text-h1 font-bold mb-4">
            AI Development
          </h1>
          <h6 className="text-p lg:text-h6 mb-8 max-w-[700px] mx-auto">
            Discover our unique approach to AI. We don't follow hype; we focus
            on real projects that deliver quick, tangible results for businesses
            and end-users.
          </h6>
          <button
            className="btn-primary btn-primary_large text-moonstone-300 bg-white hover:bg-white_A92"
            onClick={toggleContactUs}
          >
            Contact Us
          </button>
        </div>
      </div>
    </section>

    <div
      data-id="services-title"
      className="container mx-auto 2xl:max-w-default flex flex-col items-center text-center px-4 lg:px-8 mt-24"
    >
      <h2 className="text-h3 md:text-h2 mb-2 font-semibold text-neutral-300">
        Complete solutions
      </h2>
      <h6 className="text-p md:text-h6 mb-14 max-w-small">
        We cover all stages of AI development, from shaping products and
        features to deploying in production environments. We adapt to your needs
        and support you at any step of your AI journey.
      </h6>
    </div>
    <section data-id="service-list" className="px-4 lg:px-8 mb-24">
      <div className="container 2xl:max-w-default mx-auto">
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {aiServices.map(({ icon, title, text }, index) => (
            <li key={index}>
              <FontAwesomeIcon
                icon={icon}
                className="text-moonstone-300 h-10 w-10 mb-4"
              />
              <h4 className="text-h4 font-semibold text-neutral-300 mb-4">
                {title}
              </h4>
              <p className="text-p">{text}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>

    <div
      data-id="services-title"
      className="container mx-auto 2xl:max-w-default flex flex-col items-center text-center px-4 lg:px-8 mt-24"
    >
      <h2 className="text-h3 md:text-h2 mb-2 font-semibold text-neutral-300">
        We believe we can make people's lives better
      </h2>
      <h6 className="text-p md:text-h6 mb-14 max-w-small">
        Filipe Névola, our CEO, leads our AI projects from conception to
        execution. He's passionate about solving challenges and improving
        people's lives, often using AI as an effective tool to achieve these
        goals.
      </h6>

      <div className="max-w-[800px] w-full">
        <div className="flex gap-8 flex-col sm:flex-row">
          <ul className="shrink-0 grow-0">
            <ExpertList showPosition={false} showOnly="ceo" />
          </ul>
          <div className="mx-auto text-left">
            <FontAwesomeIcon
              icon={faQuoteLeft}
              className="text-neutral-300 h-8 w-8 mb-4"
            />
            <h6
              data-id="cs-testimonial"
              className="text-h6 text-neutral-300 mb-4"
            >
              I hate technology by itself. I love it when it solves problems and
              improves lives. That's why I'm passionate about real-world AI
              applications. I'm excited to work with you on AI solutions that
              make a genuine difference in people's lives.
            </h6>
            <h6 className="text-h6">
              — Filipe Névola, Quave CEO
            </h6>
          </div>
        </div>
      </div>
    </div>

    <section className="px-4 lg:px-8 mb-24 mt-24">
      <div className="container 2xl:max-w-default mx-auto flex gap-12 flex-col lg:flex-row">
        <div
          data-id="meteor-services-title"
          className="shrink-0 grow-0 lg:basis-[360px] xl:basis-[460px]"
        >
          <h3 className="text-h3 text-neutral-300 font-semibold mb-2">
            What you should expect from us?
          </h3>
          <h6 className="text-p md:text-h6 text-neutral-500 mb-6">
            People dissatisfied with the status quo, eager to grow their company
            alongside you.
          </h6>
        </div>
        <div className="flex-auto">
          <ul className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-12">
            <li>
              <h5 className="text-h5 text-neutral-300 font-semibold mb-2">
                Result focused
              </h5>
              <p className="text-p text-neutral-500">
                We don't want to sell hours or projects; we want to sell results
                that will make your business grow. Then if you grow, we grow.
              </p>
            </li>
            <li>
              <h5 className="text-h5 text-neutral-300 font-semibold mb-2">
                Challenge status quo
              </h5>
              <p className="text-p text-neutral-500">
                While we respect best practices, we believe innovation comes
                from challenging the status quo. We're not afraid to explore new
                ideas.
              </p>
            </li>
            <li>
              <h5 className="text-h5 text-neutral-300 font-semibold mb-2">
                Nerds
              </h5>
              <p className="text-p text-neutral-500">
                We are addicted to learning. We are always looking for new
                technologies and methodologies to improve our work and the
                results of our clients.
              </p>
            </li>
            <li>
              <h5 className="text-h5 text-neutral-300 font-semibold mb-2">
                Business nerds
              </h5>
              <p className="text-p text-neutral-500">
                We are not just nerds. We are business nerds. We understand that
                technology is a tool to solve business problems and improve
                people's lives.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </>
);
export const AiServicePage = ({ ...rest }) => <ConsultingService {...rest} />;
