import React from 'react';
import { RoutePaths } from '../RoutePaths';

export const sekrContent = {
  id: 'sekr',
  title: 'Enhancing the Camping Experience with AI',
  featuredImageURL: '/images/case-study-sekr-1.jpg',
  featuredImageSet:
    '/images/case-study-sekr-1.jpg 1x, /images/case-study-sekr-1@2x.jpg 2x',
  featuredLogoURL: '/images/case-study-sekr-logo.png',
  authorName: 'Jordan Schwartz',
  authorPosition: 'CPO',
  authorPhotoURL: '/images/case-study-sekr-client.jpg',
  company: {
    name: 'Peace Vans Enterprises LLC',
    size: '50-100',
    industry: 'Camping Vans',
    location: 'USA',
    description:
      'Peace Vans is a family of businesses focused on getting you outside - experiencing life in new and profound ways.',
  },
  productName: 'Sēkr',
  productURL: 'https://sekr.com/',
  services:
    'Web Development, Mobile Development, UI/UX Design, DevOps, zCloud, Performance Analysis',
  techStack: [
    'openai',
    'langchain.webp',
    'qdrant',
    'react',
    'mongo',
    'nodejs',
    'meteor',
  ],
  testimonial:
    'Quave brought Sekr back to life, delivering exactly what we needed in record time. From AI-driven trip planning to a seamless cross-platform experience, they rebuilt our app with modern features and exceeded expectations. Their ability to meet our summer deadline was crucial, and the results speak for themselves.',
  URL: RoutePaths.CASE_STUDY_SEKR,
  tags: ['camping', 'web-dev', 'mobile-dev', 'design', 'dev-ops', 'meteor'],
  text: (
    <>
      <h4 className="text-h4 text-neutral-300 font-semibold mb-4">Overview</h4>
      <p className="text-p mb-4">
        In 2024, Sekr, an app designed for campers and outdoor enthusiasts, was
        acquired by Harley Sitner. With over 80,000 users and more than 60,000
        camp locations mapped, the app had built a strong community. However,
        the previous owners had abandoned the app, and it had not been updated
        for quite some time. Jordan (CPO) and Harley, passionate about the
        camping lifestyle, saw Sekr’s potential and wanted to bring it back to
        life.
      </p>
      <p className="text-p mb-4">
        Their goal was not only to revive the app but to enhance it with new
        features, particularly by integrating AI to help users create
        personalized camping itineraries. They also wanted to make the app
        available on desktop, expanding its reach.
      </p>
      <h4 className="text-h4 text-neutral-300 font-semibold mb-4">
        Challenges
      </h4>
      <p className="text-p mb-4">
        Sekr's outdated architecture posed a major challenge. After years
        without investment, the app was difficult to maintain, let alone enhance
        with modern features like AI. The old system wasn’t suitable for scaling
        or adding new technology.
      </p>
      <p className="text-p mb-4">
        Moreover, the introduction of AI brought its own challenges. Harley and
        Jordan envisioned a seamless, conversational AI that could assist users
        in planning trips based on their personal preferences. Creating an
        intuitive design for this AI feature was critical to ensure users could
        interact easily and feel comfortable using it.
      </p>
      <h4 className="text-h4 text-neutral-300 font-semibold mb-4">
        Rebuilding the App
      </h4>
      <p className="text-p mb-4">
        After evaluating the existing codebase, it became clear that trying to
        work with the outdated technologies would slow down our ability to
        deliver the enhanced features. As the old technologies were not suited
        to create a desktop experience then we had to rebuild the app from
        scratch. This allowed us to create a modern, scalable solution that
        could accommodate both the AI trip planner and a desktop version.
      </p>
      <h4 className="text-h4 text-neutral-300 font-semibold mb-4">
        Designing the AI Interaction
      </h4>
      <p className="text-p mb-6">
        The core challenge was to make the AI feature feel intuitive and
        user-friendly. We focused on creating a conversational flow between the
        user and the AI, allowing users to easily input their preferences—like
        favorite activities or locations—and have the AI generate personalized
        itineraries. Our goal was to make the interaction feel natural, ensuring
        users felt guided rather than overwhelmed by the technology.
      </p>

      <div className="bg-neutral-900 rounded-8 p-6 mb-8">
        <h4 className="text-h4 text-neutral-300 font-semibold mb-4">
          AI Deep Dive
        </h4>
        <p className="text-p mb-6">
          Implementing AI in our system presented unique challenges,
          particularly in integrating our extensive database of over 60,000
          locations. To tackle this, we adopted a RAG (Retrieval-Augmented
          Generation) approach using QDrant database. This method of enhancing
          data with our proprietary information introduced an additional layer
          of complexity: maintaining geographic relevance across all searches to
          ensure suggested places remain within the route's boundaries.
        </p>

        <p className="text-p mb-6">
          Our solution involved breaking down the problem into manageable steps.
          We began by deciphering the route using an external API, while
          simultaneously allowing users to express their preferences in natural
          language. This process is followed by a semantic search on Quadrant to
          retrieve relevant locations from our database. The culmination of this
          data is then processed by external LLMs, which combine it with
          publicly available information to generate comprehensive results.
        </p>

        <p className="text-p mb-6">
          The journey doesn't end there. To ensure a rich user experience, we
          further enhance the data for locations not found in our database. This
          involves reaching out to additional external APIs to fill in any
          information gaps. Remarkably, all of these intricate processes occur
          seamlessly in the background as users navigate through the chat
          interface.
        </p>

        <p className="text-p mb-2">
          The final challenge lay in presenting this wealth of information to
          users in an intuitive chat format. We've designed the interface to
          allow users to interact with results effortlessly – clicking links,
          hovering for more details, adding locations to the map, or visualizing
          them directly. Orchestrating these diverse elements into a cohesive,
          user-friendly experience that requires minimal learning was a
          significant achievement, enabling users to harness the full power of
          our app with ease.
        </p>
      </div>
      <h4 className="text-h4 text-neutral-300 font-semibold mb-4">
        Improving Existing Features
      </h4>
      <p className="text-p mb-6">
        In addition to developing the AI, we enhanced many of the existing
        features from the old app. We rethought the user experience to make
        features more accessible and easier to use, delivering a more polished
        product.
      </p>
      <h4 className="text-h4 text-neutral-300 font-semibold mb-4">
        Technology Stack
      </h4>
      <p className="text-p mb-4">
        We chose Meteor to rebuild the app, as it enabled us to create a
        cross-platform solution that worked seamlessly on both mobile and
        desktop. This also ensured a faster time-to-market. Hosting on zCloud
        provided excellent support from their team and a solid uptime and
        performance.
      </p>
      <h4 className="text-h4 text-neutral-300 font-semibold mb-4">Results</h4>
      <p className="text-p mb-4">
        Quave delivered the new version of Sekr in under three months, just in
        time for the summer camping season. The app retained its original
        features while adding a new AI-powered trip planner, allowing users to
        create personalized itineraries through a seamless, conversational
        interface. We also ensured that the app worked consistently across both
        desktop and mobile platforms, providing flexibility for users.
      </p>
      <p className="text-p mb-4">
        The app has not only been revived but transformed into a more modern,
        intuitive tool that meets the needs of today's camping enthusiasts.
      </p>
      <h4 className="text-h4 text-neutral-300 font-semibold mb-4">
        Key Features
      </h4>
      <ul className="list-disc mb-4">
        <li className="ml-6 mb-2 text-p">
          <b>AI-driven trip planning:</b> Users can create customized
          itineraries based on preferences through an intuitive, conversational
          AI interface.
        </li>
        <li className="ml-6 mb-2 text-p">
          <b>Cross-platform accessibility:</b> Available on both mobile and
          desktop.
        </li>
        <li className="ml-6 mb-2 text-p">
          <b>Enhanced features:</b> Improved design and usability for legacy
          features from the old app.
        </li>
        <li className="ml-6 mb-2 text-p">
          <b>Rapid delivery:</b> Full rebuild and feature enhancements completed
          in under 3 months.
        </li>
      </ul>
    </>
  ),
};
