import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { RoutePaths } from './RoutePaths';
import { HomePage } from './HomePage';
import { NotFoundPage } from './NotFoundPage';
import { AboutUsPage } from './AboutUsPage';
import { PrivacyPolicyPage } from './PrivacyPolicyPage';
import { TermsConditionsPage } from './TermsConditionsPage';
import { SostenaCSPage } from './CaseStudySostena';
import { SekrCSPage } from './CaseStudySekr';
import { PathableCSPage } from './CaseStudyPathable';
import { MeteorCSPage } from './CaseStudyMeteor';
import { PetParkerCSPage } from './CaseStudyPetParker';
import { MontiApmCSPage } from './CaseStudyMontiAPM';
import { AklivityCSPage } from './CaseStudyAklivity';
import { UnlocCSPage } from './CaseStudyUnloc';
import { LempireCSPage } from './CaseStudyLempire';
import { SneakpeekCSPage } from './CaseStudySneakpeak';
import { EatFreshCSPage } from './CaseStudyEatFresh';
import { FavroCSPage } from './CaseStudyFavro';
import { BemarkeCSPage } from './CaseStudyBemarke';
import { PoliDigitalCSPage } from './CaseStudyPoliDigital';
import { ZcloudCSPage } from './CaseStudyZcloud';
import { CaseStudiesPage } from './CaseStudies';
import { WebDevServicePage } from './ServiceWebDev';
import { MobileDevServicePage } from './ServiceMobileDev';
import { DesignServicePage } from './ServiceDesign';
import { ConsultingServicePage } from './ServiceConsulting';
import { CloudSolutionsServicePage } from './ServiceCloudSolutions';
import { MarketingWebsitesServicePage } from './ServiceWebsites';
import { PerformanceAnalysisServicePage } from './ServicePerfomanceAnalysis';
import { MeteorServicePage } from './ServiceMeteor';
import { ReferralPage } from './Referral';
import { EducationPage } from './EducationPage';
import { AiServicePage } from './ServiceAi';

export const AppRoutes = ({ toggleContactUs }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <Routes>
      <Route
        path={RoutePaths.HOME}
        element={<HomePage toggleContactUs={toggleContactUs} />}
      />
      <Route path={RoutePaths.CASE_STUDIES} element={<CaseStudiesPage />} />
      <Route path={RoutePaths.CASE_STUDY_SEKR} element={<SekrCSPage />} />
      <Route path={RoutePaths.CASE_STUDY_SOSTENA} element={<SostenaCSPage />} />
      <Route
        path={RoutePaths.CASE_STUDY_PATHABLE}
        element={<PathableCSPage />}
      />
      <Route path={RoutePaths.CASE_STUDY_METEOR} element={<MeteorCSPage />} />
      <Route
        path={RoutePaths.CASE_STUDY_PETPARKER}
        element={<PetParkerCSPage />}
      />
      <Route
        path={RoutePaths.CASE_STUDY_MONTIAPM}
        element={<MontiApmCSPage />}
      />
      <Route
        path={RoutePaths.CASE_STUDY_AKLIVITY}
        element={<AklivityCSPage />}
      />
      <Route path={RoutePaths.CASE_STUDY_UNLOC} element={<UnlocCSPage />} />
      <Route path={RoutePaths.CASE_STUDY_LEMPIRE} element={<LempireCSPage />} />
      <Route
        path={RoutePaths.CASE_STUDY_SNEAKPEEK}
        element={<SneakpeekCSPage />}
      />
      <Route
        path={RoutePaths.CASE_STUDY_EATFRESH}
        element={<EatFreshCSPage />}
      />
      <Route path={RoutePaths.CASE_STUDY_FAVRO} element={<FavroCSPage />} />
      <Route path={RoutePaths.CASE_STUDY_BEMARKE} element={<BemarkeCSPage />} />
      <Route
        path={RoutePaths.CASE_STUDY_POLIDIGITAL}
        element={<PoliDigitalCSPage />}
      />
      <Route path={RoutePaths.CASE_STUDY_ZCLOUD} element={<ZcloudCSPage />} />
      <Route
        path={RoutePaths.SERVICES_WEB_DEV}
        element={<WebDevServicePage toggleContactUs={toggleContactUs} />}
      />
      <Route
        path={RoutePaths.SERVICES_MOBILE_DEV}
        element={<MobileDevServicePage toggleContactUs={toggleContactUs} />}
      />
      <Route
        path={RoutePaths.SERVICES_MOBILE_DEV}
        element={<MobileDevServicePage toggleContactUs={toggleContactUs} />}
      />
      <Route
        path={RoutePaths.SERVICES_DESIGN}
        element={<DesignServicePage toggleContactUs={toggleContactUs} />}
      />
      <Route
        path={RoutePaths.SERVICES_CONSULTING}
        element={<ConsultingServicePage toggleContactUs={toggleContactUs} />}
      />
      <Route
        path={RoutePaths.SERVICES_AI}
        element={<AiServicePage toggleContactUs={toggleContactUs} />}
      />
      <Route
        path={RoutePaths.SERVICES_DEVOPS}
        element={
          <CloudSolutionsServicePage toggleContactUs={toggleContactUs} />
        }
      />
      <Route
        path={RoutePaths.SERVICES_MARKETING_WEBSITES}
        element={
          <MarketingWebsitesServicePage toggleContactUs={toggleContactUs} />
        }
      />
      <Route
        path={RoutePaths.SERVICES_PERFORMANCE_ANALYSIS}
        element={
          <PerformanceAnalysisServicePage toggleContactUs={toggleContactUs} />
        }
      />
      <Route
        path={RoutePaths.SERVICES_METEOR}
        element={<MeteorServicePage toggleContactUs={toggleContactUs} />}
      />
      <Route path={RoutePaths.REFERRAL} element={<ReferralPage />} />
      <Route path={RoutePaths.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      <Route
        path={RoutePaths.TERMS_AND_CONDITIONS}
        element={<TermsConditionsPage />}
      />
      <Route path={RoutePaths.EDUCATION} element={<EducationPage />} />
      <Route path={RoutePaths.ABOUT_US} element={<AboutUsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
